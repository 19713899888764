import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../engine/GlobalState';
import './QuickReplieComponent.css';

const QuickReplieComponent = (props) => {
    const context = useContext(GlobalStateContext);

    const onRead = () => {
        let { api, updates, history } = context;
        if (!(updates.value || []).length) return;

        history.set((ph) => ph.concat(updates.value));
        updates.set([]);

        api.markAsRead()
            .then((result) => {
                // console.log('Result of update was marked as read: ', result)
            })
            .catch(() => {});
    };

    const submitQuickReplie = () => {
        onRead();
        context.sendMessage(props.text);
    };

    return (
        <div
            className="ACCW_QuickReplieComponent_main"
            style={{ background: context.style.value.buttonColor || 'white' }}
            onClick={submitQuickReplie}
        >
            <span
                style={{
                    color: context.style.value.buttonTextColor || '006cff',
                    fontSize: `${context.style.value.messageFontSize}px`,
                }}
                className="ACCW_QuickReplieComponent_main_text"
            >
                {props.text}
            </span>
        </div>
    );
};

export default QuickReplieComponent;
