import React, { useState, useEffect } from 'react';
import './NotificationComponent.css';
import svgLib from './svglib';
import newChatSound from './sounds/new_chat.mp3';
import newMsgSound from './sounds/new_msg.mp3';

const NotificationComponent = () => {
    const [isShow, setIsShow] = useState(false);
    const [message, setMessage] = useState('');
    const [color, setColor] = useState('');
    const [icon, setIcon] = useState('');
    const [fontSize, setFontSize] = useState(14);

    const [showClose, setShowClose] = useState(true);

    let timer;

    const closeByTime = (time) => {
        clearTimeout(timer);
        setShowClose(false);
        timer = setTimeout(() => { setIsShow(false); }, time * 1000);
    };


    const setDisplayText = (text) => {
        let changedText;
        if (text.length > 600) {
            setFontSize(10);
            changedText = `${text.substring(0, 600)}...`;
        } else if (text.length > 106) {
            setFontSize(10);
        } else if (text.length > 46) {
            setFontSize(12);
        } else {
            setFontSize(14);
        }
        setMessage(changedText || typeof text === 'object' ? text.message : text);
    };

    const error = (mesage, time) => {
        clearTimeout(timer);

        setShowClose(true);
        setDisplayText(mesage);
        setColor('#FF6F61');
        setIcon(svgLib.errorTwo('white', { height: '20px', width: '20px' }));
        setIsShow(true);

        if (time) closeByTime(time);
    };
    const warning = (mesage, time) => {
        clearTimeout(timer);

        setShowClose(true);

        setDisplayText(mesage);
        setColor('#FFBB00');
        setIcon(svgLib.information('white', { height: '20px', width: '20px' }));
        setIsShow(true);
        if (time) closeByTime(time);
    };
    const info = (mesage, time) => {
        clearTimeout(timer);

        setShowClose(true);
        setDisplayText(mesage);
        setColor('#79CC66');
        setIcon(svgLib.check('white', { height: '20px', width: '20px' }));
        setIsShow(true);
        if (time) closeByTime(time);
    };

    const sound = (type = 'new_chat_sound') => {
        const audioElement = document.querySelector(`#${type}`);
        audioElement && audioElement.play();
    };

    const close = () => {
        // clearTimeout(timer);
        setIsShow(false);
    };
    useEffect(() => {
        window.notification = {
            error,
            warning,
            info,
            close,
            sound,
        };
        // window.feedBackPopUp('r', err.message);
        window.feedBackPopUp = (mode, mess, delay) => {
            switch (mode) {
                case 'r':
                    window.notification.error(mess, delay);
                    break;
                case 'y':
                    window.notification.warning(mess, delay);
                    break;
                case 'g':
                    window.notification.info(mess, delay);
                    break;
                default:
                    break;
            }
        };
    }, []);


    return (
        <div className={`pNotificationComponent_fixed ${isShow ? 'pNotificationComponent_fixed_show' : null}`}>
            <div className="pNotificationComponent_main" style={{ background: color }}>
                <div className="pNotificationComponent_image">
                    {icon}
                </div>

                <div className="pNotificationComponent_container">
                    <div className="pNotificationComponent_message" style={{ fontSize }}>
                        {message}
                    </div>
                </div>

                {showClose
                    ? (
                        <div className="pNotificationComponent_header">

                            <div className="pNotificationComponent_close_button" onClick={() => setIsShow(false)}>
                                {svgLib.cross('white', { height: '10px', width: '10px' })}
                            </div>

                        </div>
                    )

                    : null}
            </div>
            <audio id="new_chat_sound" src={newChatSound} type="audio/mpeg"><track kind="captions" /></audio>
            <audio id="new_msg_sound" src={newMsgSound} type="audio/mpeg"><track kind="captions" /></audio>


        </div>
    );
};


export default NotificationComponent;
