import React, { } from 'react';



const ErrorComponent = props => {
  // console.log(props)


    return (
        <div className="ACCW_ErrorComponent_main">
          <p className="ACCW_ErrorComponent_Text" >{props.data.message}</p>
        </div>
    );
}

export default ErrorComponent;