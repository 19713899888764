import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { GlobalState } from './engine/GlobalState';
import App from './viewComponents/App/App';
import ButtonComponent from './viewComponents/ButtonComponent/ButtonComponent';
import FrameWrapperComponent from './viewComponents/FrameWrapperComponent/FrameWrapperComponent';

const ACCW = () => (
    // React Router handler to render needed React component by path
    <GlobalState>
        <Router>
            <Switch>
                <Route exact path="/" component={App} />
                <Route exact path="/button" component={ButtonComponent} />
                <Route exact path="/chat" component={FrameWrapperComponent} />
                {/* <Route path="*" component={ButtonComponent} /> */}
            </Switch>
        </Router>
    </GlobalState>
);
export default ACCW;
