const history = (options) => {
    const { CIS_URL } = options

    const get = (settings) => {
        return new Promise((resolve, reject) => {
            // console.log('Taking history by settings: ', settings)

            let url = new URL(`${CIS_URL}/messages`)

            settings && Object.keys(settings).forEach(key => url.searchParams.append(key, settings[key]))

            fetch(url, {
                mode: 'cors',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
                .then(response => response.json())
                .then(messages => {
                    if (messages.error) return reject(messages.error)

                    resolve(messages)
                })
                .catch(reject)
        })
    }

    const send = (message = {}) => {
        return new Promise((resolve, reject) => {
            // console.log('Sending message: ', message)

            let url = new URL(`${CIS_URL}/messages`)

            url.searchParams.append('agentID', message.agentID)
            url.searchParams.append('userID', message.userID)

            fetch(url, {
                mode: 'cors',
                method: 'post',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([message])
            })
                .then(response => response.json())
                .then(result => {
                    if (result.error) return reject(result.error)

                    resolve(result)
                })
                .catch(reject)
        })

        // console.log('send ', message)
    }

    return Object.create({
        get, send
    })
}

export default history;