import React, { useContext } from 'react';
import { GlobalStateContext } from '../../engine/GlobalState';
import { makeGradient } from '../../utils/color';
import QuickReplieComponent from './QuickReplieComponent/QuickReplieComponent';
import './QuickRepliesComponent.css';

const QuickRepliesComponent = (props) => {
    const context = useContext(GlobalStateContext);
    const replies = context.replies.value;
    return replies && replies.length ? (
        <div
            className="ACCW_QuickRepliesComponent_main"
            style={{
                background: makeGradient(context.style.value.backgroundColor, 'white', 70),
            }}
        >
            <div
                className={`ACCW_QuickRepliesComponent_main_wrapper_button ${
                    context.styleGradient.value && 'AACW_qr_garadient'
                }`}
            >
                {replies &&
                    replies.map((item, i) => {
                        return <QuickReplieComponent key={i} text={item} />;
                    })}
            </div>
        </div>
    ) : (
        <div />
    );
};

export default QuickRepliesComponent;
