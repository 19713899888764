const auth = (options) => {
    const { ARCHITECTOR_URL } = options

    const alias = (anonymousID, agentID, userID) => {
        return new Promise((resolve, reject) => {
            let url = new URL(`${ARCHITECTOR_URL}/anonymous/alias`)

            fetch(url, {
                mode: 'cors',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ anonymousID, agentID, userID })
            })
                .then(response => response.json())
                .then(resp => {
                    if (!resp.ok) return reject(resp.result)

                    resolve(resp.result)
                })
                .catch(reject)
        })
    }

    return Object.create({
        alias
    })
}

export default auth;