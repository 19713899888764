import React, { } from 'react';

import './ImgMessegeComponent.css';
import MessegeButtonComponent from '../MessegeButtonComponent/MessegeButtonComponent'


const ImgMessegeComponent = props => {
  return (
    <div className="ACCW_ImgMessegeComponent">
      <div className="ACCW_ImgMessegeComponent_Text_wrapper"> <img alt="" src={props.dataMessege.message.payload} className="ACCW_ImgMessegeComponent_img" /></div>
      {props.dataMessege.message.buttons && props.dataMessege.message.buttons.length ? <div className="ACCW_TextMessegeComponent_button_r">
        <div className="ACCW_TextMessegeComponent_button_wrapper" >
          {props.dataMessege.message.buttons.map((item, i) => <MessegeButtonComponent key={i} databutton={item} />)}

        </div>
      </div> : null
      }
    </div>
  );

}

export default ImgMessegeComponent;