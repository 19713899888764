import history from './history'
import updates from './updates'
import auth from './auth'

const connect = (options) => {
    return Object.create({
        history: history(options),
        updates: updates(options),
        auth: auth(options)
    })
}

export default connect