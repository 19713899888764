/* eslint-disable no-useless-escape */
import React, { useEffect, useContext, useState } from "react";

import "./ButtonComponent.css";
import { GlobalStateContext } from "../../engine/GlobalState";
import closeNotification from "./close_red.svg";

const COOKIE_MAX_AGE = 60 * 60 * 24 * 365 * 4; //4 years max age
function getCookie(name) {
  var matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookie(name, valueForCookie = "") {
  if (name) {
    let params = {
      [name]:
        valueForCookie && typeof valueForCookie === "object"
          ? JSON.stringify(valueForCookie)
          : valueForCookie.toString(),
      path: "/",
      "max-age": COOKIE_MAX_AGE,
    };

    let resultCookie = Object.keys(params)
      .map((param) => `${param}=${params[param]}`)
      .join("; ");

    // console.log('Result cookie: ', resultCookie)

    document.cookie = resultCookie;
  }
}

const ImgAvatar = (props) => {
  const context = useContext(GlobalStateContext);

  const isChatOpen = () => {
    // console.log('Togle chat method with value: ', !context.isChatOpen.value)

    context.isChatOpen.value
      ? window.parent.postMessage("close", "*")
      : window.parent.postMessage("open", "*");
    context.isChatOpen.set(!context.isChatOpen.value);
    !context.isChatOpen.value && context.updates.set([]);
  };

  // useEffect(() => {
  //     document.querySelector('.ACCW_Button_IMG').classList.toggle("ACCW_Button_IMG-animation")
  //     return () => {
  //         document.querySelector('.ACCW_Button_IMG').classList.toggle("ACCW_Button_IMG-animation")
  //     };
  // }, []);
  // let toggleAnimation = () => {
  //     document.querySelector('.ACCW_Button_IMG').classList.toggle("ACCW_Button_IMG-animation")

  // }

  return (
    <div
      className="ACCW_Button_IMG_wrapper"
      //  onClick={toggleAnimation}

      onClick={isChatOpen}
    >
      <img
        alt=""
        src={context.agent.value.image}
        className="ACCW_Button_IMG ACCW_Button_IMG-animation"
      />
    </div>
  );
};

const NotificationComponent = (props) => {
  return (
    <div className="ACCW_NotificationComponent">
      <span className="ACCW_NotificationComponent_span">
        {props.numberMes >= 10 ? "9+" : props.numberMes}
      </span>
    </div>
  );
};

let time;

const showMessage = () => {
  try {
    document.querySelector(".ACCW_LastImgComponent") &&
      window.parent.postMessage(
        `setSizeHeight:${
          document
            .querySelector(".ACCW_LastImgComponent")
            .getBoundingClientRect().height
        }`,
        "*"
      );
  } catch {
    console.log("error when show message");
  }
};

const startShowMessage = () => {
  time = setInterval(showMessage, 600);
};

const closeMessage = () => {
  clearInterval(time);
};

const LastImgComponent = (props) => {
  const context = useContext(GlobalStateContext);
  let message = context.updates.value.filter((item) => {
    return item.message.type === "text";
  });
  // console.log(message)

  let messageText =
    message.length && message[message.length - 1].message.payload;
  // console.log(message)

  const [opacity, setOpasity] = useState(0);

  let messaheWrapper = React.createRef();

  let sendCloseMessage = () => {
    context.viewMessage.set(false);
    window.parent.postMessage("close_message", "*");

    if (typeof Storage !== "undefined") {
      // Code for localStorage/sessionStorage.
      setCookie(
        "last_massage_close_id",
        `${message.length && message[message.length - 1]._id}`
      );
    } else {
      // console.log('Sorry! No Web Storage support..')
    }
  };

  // useEffect(() => {

  //     setTimeout(() => {
  //         setOpasity(1);

  //     }, 700);
  // }, [])

  let bindEvent = (element, eventName, eventHandler) => {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, eventHandler);
    }
  };

  useEffect(() => {
    let textArray = context.updates.value.filter((item) => {
      return item.message.type === "text";
    });

    if (
      !context.isChatOpen.value &&
      textArray.length &&
      context.updates.value !== 0 &&
      ((textArray && textArray[textArray.length - 1]) || {})._id !==
        getCookie("last_massage_close_id")
    ) {
      closeMessage();

      startShowMessage();
      // setTimer(time)
      //    clearInterval(timerMessage)
    } else {
      window.parent.postMessage("close_message", "*");
      // setTimeout(() => {
      //     window.parent.postMessage('close_message', '*')

      // }, 700)
    }
  }, [context.viewMessage.value]);

  useEffect(() => {
    bindEvent(window, "message", function (e) {
      if (e.data === "stopShowMessage") {
        closeMessage();
        setOpasity(1);
      }
    });
  }, []);

  const openChatMessage = () => {
    context.isChatOpen.value
      ? window.parent.postMessage("close", "*")
      : window.parent.postMessage("open", "*");
  };

  let textArray = context.updates.value.filter((item) => {
    return item.message.type === "text";
  });

  let contentMessage;
  if (
    !context.isChatOpen.value &&
    textArray.length &&
    context.updates.value !== 0 &&
    ((textArray && textArray[textArray.length - 1]) || {})._id !==
      getCookie("last_massage_close_id")
  ) {
    contentMessage = (
      <div
        ref={messaheWrapper}
        style={{ opacity: `${opacity}` }}
        className="ACCW_LastImgComponent"
      >
        {context.viewMessage.value && (
          <div className="ACCW_LastImgComponent_wrappers">
            <div className="ACCW_LastImgComponent_message">
              <p
                className="ACCW_LastImgComponent_message_text"
                onClick={openChatMessage}
              >
                {messageText}
              </p>
            </div>
            <div
              onClick={sendCloseMessage}
              className="ACCW_LastImgComponent_close_button"
            >
              <img src={closeNotification} alt="" />
            </div>
          </div>
        )}
      </div>
    );
  } else {
    contentMessage = <div></div>;
  }

  return <div>{contentMessage}</div>;
};

const ButtonComponent = (props) => {
  const context = useContext(GlobalStateContext);

  let bindEvent = (element, eventName, eventHandler) => {
    if (element.addEventListener) {
      element.addEventListener(eventName, eventHandler, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + eventName, eventHandler);
    }
  };
  useEffect(() => {
    bindEvent(window, "message", function (e) {
      if (e.data.split("###")[0] === "read") {
        // console.log('Taken event that messages should be cleared with LASTID: ', lastID)
        context.updates.set([]);
      } else {
        // console.log(e.data)
        let isOpen;
        if (e.data === "open") {
          isOpen = true;
          context.isChatOpen.set(isOpen);
        }
        if (e.data === "close") {
          isOpen = false;
          context.isChatOpen.set(isOpen);
        }
      }
    });
  }, []);

  return (
    <div
      style={{
        height: "60px",
        width: "60px",
        position: "absolute",
        bottom: "10px",
        right: "10px",
      }}
    >
      <div className="ACCW_Button_Main " id="ACCW_button">
        {
          // context.isChatOpen.value ? <ImgClose /> :
          <ImgAvatar />
        }
        {!context.isChatOpen.value && context.updates.value.length !== 0 && (
          <NotificationComponent numberMes={context.updates.value.length} />
        )}
        {!context.isChatOpen.value && context.updates.value.length !== 0 && (
          <LastImgComponent />
        )}
      </div>
    </div>
  );
};

export default ButtonComponent;
