import React, {  } from "react"
import Engine from './Engine'

const GlobalStateContext = React.createContext({})


const GlobalState = (props) => {
    let type = window.location.href.split("/")[window.location.href.split("/").length - 1].split('?')[0]
    return (
        <GlobalStateContext.Provider value={Engine(type)}>
            {props.children}
        </GlobalStateContext.Provider>
    )
}

const GlobalStateConsumer = (props) => (<GlobalStateContext.Consumer>{props.children}</GlobalStateContext.Consumer>)
export { GlobalState, GlobalStateConsumer, GlobalStateContext }

