import React from 'react';
import './MessegeComponent.css';
import MessegeBotComponent from './MessegeBotComponent/MessegeBotComponent';
import MessegeUserComponent from './MessegeUserComponent/MessegeUserComponent';

function checkIfShow(message) {
    /**
     * Here will be check by conditions if should be hidden
     */
    if (!message) {
        return false;
    }
    if (message.options && message.options.hidden) {
        return false;
    }
    return true;
}

const MessegeComponent = (props) => {
    if (!checkIfShow(props.dataMessege.message)) {
        return null;
    }

    if (props.dataMessege.from === 'user') {
        return <MessegeUserComponent number={props.number} stack={props.stack} dataMessege={props.dataMessege} />;
    } else {
        return <MessegeBotComponent number={props.number} stack={props.stack} dataMessege={props.dataMessege} />;
    }
};

export default MessegeComponent;
