import moment from 'moment';
import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../../engine/GlobalState';
import { getMessage } from '../utils';
import close from './close.svg';
import Delivery from './Delivery.svg';
import DeliveryWhite from './DeliveryWhite.svg';
import './MessegeUserComponent.css';
import Read from './Read.svg';
import ReadWhite from './ReadWhite.svg';
import refresh from './refresh.svg';

const MessegeUserComponent = (props) => {
    const context = useContext(GlobalStateContext);

    let messege = getMessage(props.dataMessege);

    const onRead = () => {
        let { api, updates, history } = context;
        if (!(updates.value || []).length) return;

        history.set((ph) => ph.concat(updates.value));
        updates.set([]);

        api.markAsRead()
            .then(() => {})
            .catch(() => {});
    };

    const onRefreshMessage = () => {
        onCloseMessage();
        onRead();
        context.sendMessage(props.dataMessege.message.payload);
    };

    const onCloseMessage = () => {
        onRead();
        let history = [...context.history.value];
        const index = context.history.value.findIndex((item) => item.timestamp === props.dataMessege.timestamp);
        history.splice(index, 1);
        context.history.set(history);
    };

    return (
        <div
            id={`accw_message_${props.number}`}
            className={`ACCW_MessegeUserComponent_messege_wrapper_user ${
                props.stack && 'ACCW_MessegeUserComponent_messege_wrapper_stack'
            } ${context.styleGradient.value && 'ACCW_gradient'}`}
        >
            <div
                style={{ background: context.styleGradient.value && 'rgba(255,255,255, 0.38)' }}
                className={`ACCW_MessegeUserComponent ${
                    props.dataMessege.sending !== undefined &&
                    !props.dataMessege.sending &&
                    'ACCW_MessegeUserComponent_not_sending'
                }`}
            >
                {messege}
                <div className="ACCW_MessegeUserComponent_status">
                    <span
                        style={{
                            color:
                                props.dataMessege.sending !== undefined &&
                                !props.dataMessege.sending &&
                                'rgba(255,255,255, 0.54)',
                        }}
                        className="ACCW_MessegeUserComponent_status_timestamp"
                    >
                        {moment(props.dataMessege.timestamp).format('HH:mm')}
                    </span>
                    <div className="ACCW_MessegeUserComponent_messege_wrapper_status">
                        {props.dataMessege.sending === undefined ? (
                            <img
                                alt=""
                                src={context.styleGradient.value ? ReadWhite : Read}
                                style={{ marginRight: '20px' }}
                            />
                        ) : props.dataMessege.sending ? (
                            <img
                                alt=""
                                style={{ marginRight: '20px' }}
                                src={context.styleGradient.value ? DeliveryWhite : Delivery}
                            />
                        ) : (
                            <span className="imgMessegeWraperFalse">
                                <img alt="" onClick={onRefreshMessage} src={refresh} />
                                <img alt="" src={close} onClick={onCloseMessage} />
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundImage:
                        context.styleGradient.value &&
                        'linear-gradient(to right, rgba(255, 255, 255, 0.38) 0%, rgba(255, 255, 255, 0) 100%)',
                }}
                className="ACCW_MessegeUserComponent_message_gradien"
            />
        </div>
    );
};

export default MessegeUserComponent;
