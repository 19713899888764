import styled from "styled-components";

export const TextareaWrapper = styled.textarea`
  ${({ placeholderColor }) =>
    `::placeholder,
      ::-webkit-input-placeholder {
        color: ${placeholderColor ? placeholderColor : "#ffffff"};
        opacity: 0.8;
      }
      :-ms-input-placeholder {
        color: ${placeholderColor ? placeholderColor : "#ffffff"};
        opacity: 0.8;
      }`};
`;
