import React, { useContext, useEffect, useRef, useState } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import { GlobalStateContext } from '../../engine/GlobalState';
import { makeGradient } from '../../utils/color';
import LoaderComponent from '../LoaderComponent/LoaderComponent';
import './ConversationComponent.css';
import MessegeComponent from './MessegeComponent/MessegeComponent';
import TypingComponent from './TypingComponent/TypingComponent';
import UnreadMessege from './UnreadMessege/UnreadMessege';

let timer;

const ConversationComponent = () => {
    const context = useContext(GlobalStateContext);
    const [loadNewMessage] = useState(false);
    const scrollRef = useRef(null);

    var toShowTyping =
        context.typing.value && (context.typing.value.action || {}).on + context.TYPING_TIMEOUT_MS > Date.now();

    const onGetMessageScroll = (e) => {
        e.preventDefault();
        if (context.isChatOpen.value && !context.historyLoading.value && e.target.scrollTop === 0) {
            !context.historyLoading.value && context.historyLoading.set(true);
            context
                .getHistory({ limit: 20, skip: context.history.value.length })
                .then((messages = []) => {
                    context.historyLoading.set(false);
                    context.history.set((ph) => messages.concat(ph));
                })
                .catch((error) => {
                    context.historyLoading.set(false);
                    context.api.emit(
                        'connection.error',
                        Object.assign(error, { message: 'Unable to connect to the server' })
                    );
                });
        }
    };

    /**Removing typing after timeout */
    useEffect(() => {
        if (toShowTyping) {
            timer = setTimeout(() => {
                context.typing.set(undefined);
            }, context.TYPING_TIMEOUT_MS);
        } else clearTimeout(timer);
    }, [context.typing.value]);

    const scrollToBottom = () => scrollRef.current.scrollToBottom();
    useEffect(() => {
        scrollToBottom();
    }, [context.history.value.length, context.updates.value.length]);
    return (
        <div
            className="ACCW_ConversationComponent"
            style={{
                background: makeGradient(
                    context.style.value.backgroundColor,
                    'linear-gradient(to right, #006cff 0%, #00bfff 100%)'
                ),
                borderBottom: `1px solid ${context.style.value.mainColor}`,
            }}
            onScroll={onGetMessageScroll}
        >
            <div className="ACCW_ConversationComponent_revers">
                <ScrollableFeed ref={scrollRef}>
                    {context.history.value.map((item, i) => {
                        let nextItem = context.history.value.length - 1 !== i ? i + 1 : i;
                        return (
                            <MessegeComponent
                                key={item._id || i}
                                number={i}
                                dataMessege={item}
                                stack={
                                    item.from === context.history.value[nextItem].from &&
                                    item !== context.history.value[nextItem]
                                }
                            />
                        );
                    })}
                    {context.updates.value.length !== 0 && (
                        <UnreadMessege>
                            {context.updates.value.map((item, i) => {
                                let nextItem = context.updates.value.length - 1 !== i ? i + 1 : i;
                                return (
                                    <MessegeComponent
                                        key={item._id || i}
                                        dataMessege={item}
                                        stack={
                                            item.from === context.updates.value[nextItem].from &&
                                            item !== context.updates.value[nextItem]
                                        }
                                    />
                                );
                            })}
                        </UnreadMessege>
                    )}

                    {/* TODO: show typing only if field "on" + 10 seconds < now() */}
                    {toShowTyping && <TypingComponent />}
                </ScrollableFeed>
            </div>
            {loadNewMessage && <LoaderComponent type="windmill" />}
        </div>
    );
};

// 1) mark as read: 1) on focus; 2) on input; 3) on send message
// 2) replieas should dissaper after message from the user | bot
// 3) sent messages showing above from the updates

export default ConversationComponent;
