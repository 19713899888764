import { EventEmitter } from "events";
import modules from "./modules";
class API extends EventEmitter {
  constructor(agentID, userID, anonymousID) {
    super();

    this.agentID = agentID;
    this.userID = userID;
    this.anonymousID = anonymousID;

    this.lastUpdateID = undefined;
    this.lastNotActionUpdateID = undefined;

    this.POLLING_INTERVAL = 10000;

    /**
     * API models initizalization
     */
    const { updates, history, auth } = modules({
      agentID,
      userID,
      CIS_URL: window._env_.REACT_APP_CIS_URL,
      ARCHITECTOR_URL: window._env_.REACT_APP_ARCHITECTOR_URL,
    });

    // this.agent = agent
    this.history = history;
    this.updates = updates;
    this.auth = auth;

    this.polling = [];
  }

  markAsRead(lastID) {
    window.parent.postMessage(
      `read###${lastID || this.lastNotActionUpdateID}`,
      "*"
    );
    return new Promise((resolve, reject) => {
      this.updates
        .markAsRead(lastID || this.lastNotActionUpdateID, {
          agentID: this.agentID,
          userID: this.userID,
        })
        .then(
          (result) => resolve(result) && this.emit("connection.established")
        )
        .catch((error) =>
          this.emit(
            "connection.error",
            Object.assign(error, { message: "Unable to connect to the server" })
          )
        );
    });
  }

  /**
   * Send postback to the server
   * @param {string} payload
   * @param {string} title
   */
  sendPostback(payload, title) {
    return this.history.send({
      agentID: this.agentID,
      userID: this.userID,
      timestamp: Date.now(),
      postback: {
        title,
        payload,
      },
    });
  }
  /**
   * Send message to the server
   * @param {String} payload string to send as a message
   * @param {Object} attributes additional params to send with update Object and save as attributes for user
   * @param {Object} messageOptions options to send with message Object (for example {options: {hidden: true}})
   */
  sendMessage(payload, attributes = {}, messageOptions = {}) {
    return this.history.send({
      agentID: this.agentID,
      userID: this.userID,
      anonymousID: this.anonymousID,
      timestamp: Date.now(),
      message: {
        type: "text",
        payload,
        options: messageOptions,
      },
      attributes,
    });
  }

  /**
   * Send file to the server
   * @param {String} payload string to send as a message
   * @param {Object} attributes additional params to send with update Object and save as attributes for user
   * @param {Object} messageOptions options to send with message Object (for example {options: {hidden: true}})
   */
  sendFile(payload, attributes = {}, messageOptions = {}) {
    return this.history.send({
      agentID: this.agentID,
      userID: this.userID,
      anonymousID: this.anonymousID,
      timestamp: Date.now(),
      message: {
        ...payload,
        options: messageOptions,
      },
      attributes,
    });
  }

  /**
   * Setting up polling with options specified
   * @param {object} options - object with polling settings like interval etc.
   */
  setupPolling(options = {}) {
    let { interval = this.POLLING_INTERVAL } = options;
    this.POLLING_INTERVAL = interval;

    // console.log('Polling has been set up with interval: ', this.POLLING_INTERVAL)

    let intervalID = setInterval(() => {
      this.updates
        .get({
          agentID: this.agentID,
          lastID: this.lastUpdateID,
          userID: this.userID,
        })
        .then((updates = []) => {
          this.emit("connection.established");
          updates.length && this.emit("updates", updates);
        })
        .catch((error) =>
          this.emit(
            "connection.error",
            Object.assign(error, { message: "Unable to connect to the server" })
          )
        );
    }, this.POLLING_INTERVAL);

    this.polling.push(intervalID);
  }

  stopPolling() {
    this.polling.forEach((item) => clearInterval(item));
    this.polling = [];
  }

  setPollingInterval(interval) {
    this.stopPolling();
    this.POLLING_INTERVAL = interval;
    this.setupPolling();
  }

  setLastUpdateID(id) {
    if (id) {
      this.lastUpdateID = id;
    }
  }

  setLastNotActionUpdateID(id) {
    if (id) {
      this.lastNotActionUpdateID = id;
    }
  }
}

export default API;
