import React, { } from 'react';

import './LoaderComponent.css';


const LoaderComponent = props => {


  let load

  switch (props.type) {
    case "windmill":
      load = (
        <div className="spinner">
          <div className="cube1"></div>
          <div className="cube2"></div>
        </div>
      )
      break;
    case "cube":
      load = (
        <div className="sk-cube-grid">
          <div className="sk-cube sk-cube1"></div>
          <div className="sk-cube sk-cube2"></div>
          <div className="sk-cube sk-cube3"></div>
          <div className="sk-cube sk-cube4"></div>
          <div className="sk-cube sk-cube5"></div>
          <div className="sk-cube sk-cube6"></div>
          <div className="sk-cube sk-cube7"></div>
          <div className="sk-cube sk-cube8"></div>
          <div className="sk-cube sk-cube9"></div>
        </div>
      )
      break;

    default:
      break;
  }



  return (
    <div className="ACCW_LoaderComponent">
      {load}
    </div>
  );

}

export default LoaderComponent;