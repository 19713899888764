const updates = (options) => {
    const { CIS_URL } = options

    const TEMP_UPDATES_TYPES = ['typing', 'replies']

    const isTemporal = type => {
        return this.TEMP_UPDATES_TYPES.some(tempType => tempType === type)
    }

    const get = (options) => {
        return new Promise((resolve, reject) => {
            // console.log('Taking updates by options: ', options)

            let url = new URL(`${CIS_URL}/updates`)

            options && Object.keys(options).forEach(key => url.searchParams.append(key, options[key]))

            fetch(url, {
                mode: 'cors',
                method: 'get',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
                .then(response => response.json())
                .then(updates => {
                    if (updates.error) return reject(updates.error)

                    resolve(updates)
                })
                .catch(reject)
        })
    }

    const markAsRead = (id, options) => {
        return new Promise((resolve, reject) => {
            // console.log(`Mark update as read by ID: ${id}`, options)

            let url = new URL(`${CIS_URL}/updates/${id}`)

            options && Object.keys(options).forEach(key => url.searchParams.append(key, options[key]))

            fetch(url, {
                mode: 'cors',
                method: 'delete',
                headers: {
                    'Access-Control-Allow-Origin': '*'
                }
            })
                .then(response => response.json())
                .then(result => {
                    if (result.error) return reject(result.error)

                    resolve(result)
                })
                .catch(reject)
        })
    }

    return Object.create({
        TEMP_UPDATES_TYPES,
        isTemporal,
        get,
        markAsRead
    })
}

export default updates;