import React from 'react';
import GalleryMessageComponent from './GalleryMessageComponent/GalleryMessageComponent';
import ImgMessegeComponent from './ImgMessegeComponent/ImgMessegeComponent';
import MediaMessageComponent from './MediaMessageComponent/MediaMessageComponent';
import TextMessegeComponent from './TextMessegeComponent/TextMessegeComponent';

export function getMessage(dataMessege) {
    let type = (dataMessege.message || {}).type || (dataMessege.action || {}).type;
    switch (type) {
        case 'text':
            return <TextMessegeComponent dataMessege={dataMessege} />;
        case 'image':
            return <ImgMessegeComponent dataMessege={dataMessege} />;
        case 'gallery':
            return <GalleryMessageComponent dataMessege={dataMessege} />;
        case 'attachment':
            return <MediaMessageComponent dataMessege={dataMessege} />;

        default:
            return null;
    }
}
