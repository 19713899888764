import moment from 'moment';
import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../engine/GlobalState';

const UnreadMessege = (props) => {
    const context = useContext(GlobalStateContext);
    const timeStamp = moment(context.updates.value[0].timestamp);
    const time = timeStamp.isSame(Date.now(), 'day') ? timeStamp.format('HH:mm') : timeStamp.format('MMMM	D');
    return (
        <>
            <div className="ACCW_UnreadMessege_messeg">
                <span
                    style={{ background: context.styleGradient.value && 'rgba(255,255,255, 0.38)' }}
                    className="ACCW_UnreadMessege_messeg_text"
                >
                    <span
                        style={{ color: context.styleGradient.value && 'rgba(255,255,255, 0.87)' }}
                        className="ACCW_UnreadMessege_messeg_textStyle"
                    >
                        {time}
                    </span>
                </span>
            </div>
            {props.children}
            {/* <div className="ACCW_UnreadMessege_messeg_wrapper">{props.children}</div> */}
        </>
    );
};

export default UnreadMessege;
