/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import moment from "moment";
import React, { useState } from 'react';
import download from './download.png';
import './MediaMessageComponent.css';
import off_value from './off_value.png';
import pauseimg from './pause.png';
import playimg from './play.png';
import valueAudio from './valueAudio.png';


const MediaMessageComponent = props => {

    let mediaBlock;

    switch (props.dataMessege.message.payload.type) {
        case 'audio':
            mediaBlock = <AudioPlayerComponent src={props.dataMessege.message.payload.url} title={props.dataMessege.message.payload.title} />
            break;
        case "video":
            mediaBlock = <VideoPlayerComponent src={props.dataMessege.message.payload.url} title={props.dataMessege.message.payload.title} />
            break;
        case 'file':
            mediaBlock = <FileComponet src={props.dataMessege.message.payload.url} title={props.dataMessege.message.payload.title} />
            break;
        default:
            mediaBlock = <FileComponet src={props.dataMessege.message.payload.url} title={props.dataMessege.message.payload.title} />
            break;
    }


    return mediaBlock

}




const AudioPlayerComponent = props => {

    let audioPlayerRef = React.createRef();

    let play = () => {
        let music = audioPlayerRef.current
        music.play();
        setPlayPlayer(true)
    }
    let pause = () => {
        let music = audioPlayerRef.current
        music.pause();
        setPlayPlayer(false)

    }


    const [curTime, setCurTime] = useState(0)
    const [fullTime, setFullTime] = useState(0)
    const [playPlayer, setPlayPlayer] = useState(false)
    const [isoNAudio, setIsoNAudio] = useState(true)
    const [volume, setVolume] = useState(100)


    let onAudioReady = (e) => {
        let music = e.target
        let length = music.duration
     
        setFullTime(length)
        var current_time = music.currentTime;
       
        setCurTime(current_time)


    }

    let timeUpdate = e => {
        setFullTime(e.target.duration)
        setCurTime(e.target.currentTime)

    }

    let setRange = e => {
        setCurTime((e.target.value * fullTime) / 100)
        let music = audioPlayerRef.current
        music.currentTime = (e.target.value * fullTime) / 100
    }


    let onVoice = () => {
    

        setIsoNAudio(true)
        let music = audioPlayerRef.current
        music.muted = false
    }
    let offVoice = () => {
    
        setIsoNAudio(false)
        let music = audioPlayerRef.current
        music.muted = true

    }

    let onSetVolume = (e) => {
        let music = audioPlayerRef.current
        setVolume(e.target.value)
        if (e.target.value === 0) {
            music.muted = true
            setIsoNAudio(false)

        } else {
            music.muted = false
            music.volume = volume * 0.01
            setIsoNAudio(true)
        }
    }

    return (
        <div className="AudioPlayerComponent_main"  >
            <audio ref={audioPlayerRef} onCanPlay={onAudioReady}
                // controls="controls"
                id="AudioPlayerComponent_Audio" onTimeUpdate={timeUpdate}  >
                {/* <source src="horse.ogg" type="audio/ogg" /> */}
                <source src={`${props.src}`} />
                Your browser does not support the audio element.
            </audio>
            <div style={{ background: '#00bfff' }} className="AudioPlayerComponent_player_wrapper" >

                <div className="AudioPlayerComponent_Button">
                    {!playPlayer ? <div className="AudioPlayerComponent_play_Button" onClick={play} >
                        <img src={playimg} alt="" />
                    </div>
                        :
                        <div className="AudioPlayerComponent_pause_Button" onClick={pause}  >
                            <img src={pauseimg} alt="" />

                        </div>
                    }
                </div>

                <div className="AudioPlayerComponent_range_text" >
                    <span style={{ display: 'flex', justifyContent: "center", overflow: "hidden" }} >
                        {moment(curTime * 1000).format('m:ss')}
                    </span>
                    <span style={{ width: '10px', textAlign: 'center' }}>
                        {` / `}
                    </span>
                    <span style={{ display: 'flex', justifyContent: "center", overflow: "hidden" }}>
                        {moment(fullTime * 1000).format('m:ss')}
                    </span>

                </div>
                <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between', width: '207px' }}>

                    <div className="AudioPlayerComponent_range_audio" >
                        <input min='0' max='100' type="range" step='1' className="audioplayer_input_range" value={(curTime * 100) / fullTime || 0} onChange={setRange} />
                    </div>
                    <div className="AudioPlayerComponent_valuem_Button">

                        <div className="AudioPlayerComponent_range_valuem_Button_icons"
                        >
                            <div className='AudioPlayerComponent_range_valuem_wrapper' >
                                <input min='0' max='100' type="range" step='1' className="audioplayer_input_range" value={volume} onChange={onSetVolume} />

                            </div>
                            {isoNAudio ? <div className="AudioPlayerComponent_range_valuem_Button_icon_on" onClick={offVoice} >
                                <img src={valueAudio} alt="" />
                            </div>
                                :
                                <div className="AudioPlayerComponent_range_valuem_Button_iconoff" onClick={onVoice} >
                                    <img src={off_value} alt="" />

                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <div className="AudioPlayerComponent_title_wrapper" >
                <div className="AudioPlayerComponent_title_wrapper_text">
                <span className="AudioPlayerComponent_file_name">
                        {props.title.split('.')[0]}
                    </span>
                    <span>
                        .
                    </span>
                    <span className="AudioPlayerComponent_format">
                        {props.title.split('.')[1]}
                    </span>
                </div>
                <div className="AudioPlayerComponent_download_wrapper" >
                    <a href={props.src} download="LA wakations promo.mp3" target="_blank">
                        <div className="AudioPlayerComponent_download_wrapper_img" >
                            <img src={download} />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MediaMessageComponent;



const VideoPlayerComponent = props => {
    const [curTime, setCurTime] = useState(0)
    const [fullTime, setFullTime] = useState(0)
    const [playPlayer, setPlayPlayer] = useState(false)
    const [isoNAudio, setIsoNAudio] = useState(true)
    const [volume, setVolume] = useState(100)

    let VideoPlayerRef = React.createRef();
    let play = () => {
        let music = VideoPlayerRef.current
        music.play();
        setPlayPlayer(true)
    }
    let pause = () => {
        let music = VideoPlayerRef.current
        music.pause();
        setPlayPlayer(false)

    }

    let onAudioReady = (e) => {
        let music = e.target
        let length = music.duration
 
        setFullTime(length)
        var current_time = music.currentTime;
    
        setCurTime(current_time)


    }

    let timeUpdate = e => {
        setFullTime(e.target.duration)
        setCurTime(e.target.currentTime)

 
    }


    let setRange = e => {
        setCurTime((e.target.value * fullTime) / 100)
        let music = VideoPlayerRef.current
        music.currentTime = (e.target.value * fullTime) / 100
    }


    let onVoice = () => {
  

        setIsoNAudio(true)
        let music = VideoPlayerRef.current
        music.muted = false
    }
    let offVoice = () => {
     
        setIsoNAudio(false)
        let music = VideoPlayerRef.current
        music.muted = true

    }

    let onSetVolume = (e) => {
        let music = VideoPlayerRef.current
        setVolume(e.target.value)
        if (e.target.value === 0) {
            music.muted = true
            setIsoNAudio(false)

        } else {
            music.muted = false
            music.volume = volume * 0.01
            setIsoNAudio(true)
        }
    }


    return (
        <div className="VideoPlayerComponent_main" >
            <div className="VideoPlayerComponent_video_wrapper">
                <video style={{ borderRadius: '4px' }} ref={VideoPlayerRef} id="videoPlayer"
                    // controls="controls"
                    onCanPlay={onAudioReady}
                    onTimeUpdate={timeUpdate}
                >
                    <source src={props.src} />

                </video>
                <div className="VideoPlayerComponent_control_wrapper" >

                    <div className="AudioPlayerComponent_Button">
                        {!playPlayer ? <div className="AudioPlayerComponent_play_Button" onClick={play} >
                            <img src={playimg} alt="" />
                        </div>
                            :
                            <div className="AudioPlayerComponent_pause_Button" onClick={pause}  >
                                <img src={pauseimg} alt="" />

                            </div>
                        }
                    </div>

                    <div className="AudioPlayerComponent_range_text" >
                        <span style={{ display: 'flex', justifyContent: "center", overflow: "hidden" }} >
                            {moment(curTime * 1000).format('m:ss')}
                        </span>
                        <span style={{ width: '10px', textAlign: 'center' }}>
                            {` / `}
                        </span>
                        <span style={{ display: 'flex', justifyContent: "center", overflow: "hidden" }}>
                            {moment(fullTime * 1000).format('m:ss')}
                        </span>

                    </div>

                    <div style={{ flex: '1', display: 'flex', justifyContent: 'space-between', width: '207px' }}>

                        <div className="AudioPlayerComponent_range_audio" >
                            <input min='0' max='100' type="range" step='1' className="audioplayer_input_range" value={(curTime * 100) / fullTime || 0} onChange={setRange} />
                        </div>
                        <div className="AudioPlayerComponent_valuem_Button">

                            <div className="AudioPlayerComponent_range_valuem_Button_icons"
                            >
                                <div className='AudioPlayerComponent_range_valuem_wrapper' >
                                    <input min='0' max='100' type="range" step='1' className="audioplayer_input_range" value={volume} onChange={onSetVolume} />

                                </div>
                                {isoNAudio ? <div className="AudioPlayerComponent_range_valuem_Button_icon_on" onClick={offVoice} >
                                    <img src={valueAudio} alt="" />
                                </div>
                                    :
                                    <div className="AudioPlayerComponent_range_valuem_Button_iconoff" onClick={onVoice} >
                                        <img src={off_value} alt="" />

                                    </div>
                                }

                            </div>
                        </div>
                    </div>



                </div>

            </div>
            <div className="AudioPlayerComponent_title_wrapper" >
                <div className="AudioPlayerComponent_title_wrapper_text">
                <span className="AudioPlayerComponent_file_name">
                        {props.title.split('.')[0]}
                    </span>
                    <span>
                        .
                    </span>
                    <span className="AudioPlayerComponent_format">
                        {props.title.split('.')[1]}
                    </span>
                </div>
                <div className="AudioPlayerComponent_download_wrapper" >
                    <a href={props.src} download="LA wakations promo.mp3" target="_blank">
                        <div className="AudioPlayerComponent_download_wrapper_img" >
                            <img src={download} />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}


const FileComponet = props => {

    return (
        <div className="FileComponet_main" >
            <div className="AudioPlayerComponent_title_wrapper" >
                 <div className="AudioPlayerComponent_download_wrapper" >
                     <a href={props.src} download="LA wakations promo.mp3" target="_blank">
                         <div className="AudioPlayerComponent_download_wrapper_img" >
                             <img src={download} />
                         </div>
                     </a>
                 </div>
                <div className="AudioPlayerComponent_title_wrapper_text">
                    <span className="AudioPlayerComponent_file_name">
                        {props.title}
                    </span>
                    {/* <span>
                        .
                    </span>
                    <span className="AudioPlayerComponent_format">
                        {props.title.split('.')[1]}
                    </span> */}
                </div>
            </div>
        </div>
    )

}