/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react';
import Swiper from 'swiper';
import { GlobalStateContext } from '../../../../engine/GlobalState';
import MessegeButtonComponent from '../MessegeButtonComponent/MessegeButtonComponent';
import './GalleryMessageComponent.css';

const GalleryMessageComponent = (props) => {
    // eslint-disable-next-line no-unused-vars
    var mySwiper = new Swiper('.swiper-container', {
        slidesPerView: 'auto',
        pagination: {
            el: '.swiper-pagination',
            // type: 'fraction',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    return (
        <div className="ACCW_GalleryMessageComponent swiper-container">
            <div className="ACCW_GalleryMessageComponentWrapper swiper-wrapper">
                {props.dataMessege.message.payload.elements.map((item, i) => {
                    return <CardGalleryComponent key={i} data={item} />;
                })}
            </div>
            <div className="swiper-pagination"></div>
            <div className="swiper-button-next ACCW_CardGalleryComponent_swiper_button ACCW_CardGalleryComponent_right">
                <i className="fas fa-chevron-right"></i>
            </div>
            <div className="swiper-button-prev ACCW_CardGalleryComponent_swiper_button ACCW_CardGalleryComponent_left">
                <i className="fas fa-chevron-left"></i>
            </div>
        </div>
    );
};

const CardGalleryComponent = (props) => {
    const context = useContext(GlobalStateContext);

    return (
        <div className="ACCW_CardGalleryComponent swiper-slide">
            <div className="ACCW_CardGalleryComponent_main_data_wrapper">
                <div className="ACCW_CardGalleryComponent_img_wrapper">
                    {props.data.image && <img className="ACCW_CardGalleryComponent_img" src={props.data.image} />}
                </div>
                <div className="ACCW_CardGalleryComponent_text_wrapper">
                    {props.data.title && (
                        <span
                            style={{ fontSize: `${context.style.value.messageFontSize}px` }}
                            className="ACCW_CardGalleryComponent_text_title"
                        >
                            {props.data.title}
                        </span>
                    )}
                    {props.data.subtitle && (
                        <p
                            style={{ fontSize: `${context.style.value.messageFontSize}px` }}
                            className="ACCW_CardGalleryComponent_text_description"
                        >
                            {props.data.subtitle}
                        </p>
                    )}
                </div>
            </div>

            {props.data.buttons && props.data.buttons.length ? (
                <div className="ACCW_TextMessegeComponent_button_r gallery_button">
                    <div className="ACCW_TextMessegeComponent_button_wrapper">
                        {props.data.buttons.map((item, i) => (
                            <MessegeButtonComponent key={i} databutton={item} />
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default GalleryMessageComponent;
