/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-throw-literal */
import React, { useContext, useState } from "react";
import { GlobalStateContext } from "../../../engine/GlobalState";
import "./FileUploadComponent.css";
import uploadPng from "./uploadPng.png";

const FileUploadComponent = () => {
  const context = useContext(GlobalStateContext);
  const [loading, setLoading] = useState(false);
  const onRead = () => {
    let { api, updates, history } = context;
    if (!(updates.value || []).length) return;

    history.set((ph) => ph.concat(updates.value));
    updates.set([]);

    api
      .markAsRead()
      .then((result) => {
        // console.log('Result of update was marked as read: ', result)
      })
      .catch(() => {});
  };
  const uploadImageToGS = async (botId, imageId, fileToUpload) => {
    if (fileToUpload.size > Number(4e6)) {
      throw new Error("File is too big. (bigger than 4 MB)");
    }
    try {
      const formData = new FormData();
      formData.append("filetoupload", fileToUpload);
      formData.append("botId", botId);
      formData.append("imageId", imageId);

      const url = new URL(
        `${window._env_.REACT_APP_APPLICATION_URL}/gateway/api/files/upload`
      );

      const options = {
        mode: "cors",
        method: "POST",
        body: formData,
      };

      const response = await fetch(url, options);
      const statusCode = response.status;
      const body = await response.json();

      if (body && body.error && typeof body.error === "string")
        body.error = { message: body.error };

      if (!response.ok) {
        throw {
          statusCode,
          message: "DEFAULT_API_ERROR",
          ...body.error,
          rawBodyTaken: body,
        };
      }
      if (body && body.error)
        throw { statusCode, message: "DEFAULT_API_ERROR", ...body.error };

      return body;
    } catch (error) {
      console.log(error);
    }
  };
  function guid() {
    return s4() + s4() + s4() + s4();
  }

  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(4);
  }
  const generateFileName = (name) => {
    return `${guid()}-${name}`.replace(/\s/g, "-");
  };
  const uploadFileToServer = async (agentId, imageId, file) => {
    setLoading(true);
    try {
      const result = await uploadImageToGS(agentId, imageId, file);
      await context.sendFile({
        type: file.type.split("/")[0] === "image" ? "image" : "attachment",
        payload:
          file.type.split("/")[0] === "image"
            ? result.url
            : {
                type: "file",
                title: file.name,
                url: encodeURI(result.url),
              },
      });
      onRead();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      window.notification.error(error.message);
    }
  };
  const fileInputHandler = () => {
    let filesToUpload = document.getElementById("input_file").files[0];

    let reader = new FileReader();

    filesToUpload && reader.readAsDataURL(filesToUpload);

    reader.onloadend = () => {
      uploadFileToServer(
        context.api.agentID,
        generateFileName(filesToUpload.name),
        filesToUpload
      );
    };

    reader.onerror = () => {
      console.log(reader.error);
    };
  };
  return (
    <div className={"file_upload_component"}>
      <label htmlFor="input_file" className="input_file_label">
        <div className={"input_file_label_image_wrapper"}>
          <img
            className={`input_file_label_image ${
              loading ? "input_file_label_image_loading" : ""
            }`}
            src={uploadPng}
          />
        </div>
      </label>
      <input type="file" id="input_file" onChange={fileInputHandler} />
    </div>
  );
};

export default FileUploadComponent;
