import React, { useContext } from 'react';
import { GlobalStateContext } from '../../../../engine/GlobalState';
import changeText from '../../../../markup/Markup';
import MessegeButtonComponent from '../MessegeButtonComponent/MessegeButtonComponent';
import './TextMessegeComponent.css';

const TextMessegeComponent = (props) => {
    const context = useContext(GlobalStateContext);

    const __html = changeText(props.dataMessege.message.payload, props.dataMessege.from);
    return (
        <div className="ACCW_TextMessegeComponent">
            <div
                className={
                    props.dataMessege.from === 'user'
                        ? 'ACCW_TextMessegeComponent_Text_wrapper_userMess'
                        : 'ACCW_TextMessegeComponent_Text_wrapper'
                }
            >
                <span
                    style={{ fontSize: `${context.style.value.messageFontSize}px` }}
                    dangerouslySetInnerHTML={{ __html: __html }}
                    className={
                        props.dataMessege.from === 'user'
                            ? 'ACCW_TextMessegeComponent_Text_userMess'
                            : 'ACCW_TextMessegeComponent_Text'
                    }
                />
            </div>
            {props.dataMessege.message.buttons && props.dataMessege.message.buttons.length > 0 && (
                <div className="ACCW_TextMessegeComponent_button_r">
                    <div className="ACCW_TextMessegeComponent_button_wrapper">
                        {props.dataMessege.message.buttons.map((item, i) => (
                            <MessegeButtonComponent key={i} databutton={item} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TextMessegeComponent;
