/* eslint-disable no-useless-escape */


const changeText = (text, from) => {


    text = text.replace(/\n$/gmi, '\n\n')


    // команди example:('/start')
    if (/(\/(\w+))/gmi.test(text) && !/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gmi.test(text)) {
        text = text.replace(/(\/(\w+))/gmi, (param) => {
            return `<span  >${param}</span>`
        })
    } else {
        // _italic
        text = text.replace(/\_(.*?)\_/gmi, (param) => {
            return `<mark class="mark_ACCW_italic" >${param.replace(/\_/gmi, '')}</mark>`
        })
    }

    // _bold  
    text = text.replace(/\*(.*?)\*/gmi, (param) => {
        return `<mark class="mark_ACCW_bold" >${param.replace(/\*/gmi, '')}</mark>`
    })


    // силка з ім'ям example([name](link))
    text = text.replace(/\[.*?\]\(.*?\)/gmi, (param) => {

        let insideScverleBreket = param.match(/\[(.*?)\]/gmi)[0].replace(/\[|\]/gmi, '')
        let insideCircleBreket = param.match(/\((.*?)\)/gmi)[0].replace(/\(|\)/gmi, '')

        return `<a class=${from === "user" ? 'message_link_user' : 'message_link_bot'} target='${/\|/gmi.test(insideCircleBreket) ? insideCircleBreket.split('|')[0] : "_blank"}'  href='${/\|/gmi.test(insideCircleBreket) ? insideCircleBreket.split('|')[1] : insideCircleBreket}'  >${insideScverleBreket}</a>`

    })

    if (/(www.youtube.com\/watch\?v=)|(youtu.be)/gmi.test(text)) {
        if (/(www.youtube.com)/gmi.test(text)) {

            text = text.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gmi, (param) => {
                return `<iframe width="280" height="150" src="https://www.youtube.com/embed/${param.split('https://www.youtube.com/watch?v=')[1]}" frameborder="0" picture-in-picture" allowfullscreen></iframe>`
            })

        }

        if (/(youtu.be)/gmi.test(text)) {

            text = text.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gmi, (param) => {
                return `<iframe width="280" height="150" src="https://www.youtube.com/embed/${param.split('https://youtu.be/')[1]}" frameborder="0" picture-in-picture" allowfullscreen></iframe>`
            })


        }



    }


    // силки звичайні
    if (!/href=/gmi.test(text) && !/<iframe/gmi.test(text) && !/<a/gmi.test(text)) {

        // силка з протоколом

        if (/(http(s)?)/gmi.test(text)) {
            text = text.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gmi, (param) => {
                return `<a class=${from === "user" ? 'message_link_user' : 'message_link_bot'} target="_blank" href='${param}' >${param}</a>`
            })
        } else {
            // силка без протокола 
            text = text.replace(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gmi, (param) => {
                return `<a class=${from === "user" ? 'message_link_user' : 'message_link_bot'} target="_blank" href='http://${param}' >${param}</a>`
            })
        }


    }
    // }

    return text


}

export default changeText